import React from "react";
import { ListViewHeader } from "@progress/kendo-react-listview";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { formatDate } from "../../../../Utils/Common/utils";

/**
 * Header Render
 * @props {React.FC<Props>}
 * @returns {React.ReactElement} The List View Header component.
 */
export const HeaderRender = (props) => {
  return (
    <ListViewHeader className="listview-header">{props.title}</ListViewHeader>
  );
};

/**
 * ListViewItemRender
 * @props {React.FC<Props>}
 * @returns {React.ReactElement} The List View Item Render component.
 */
export const ListViewItemRender = ({ dataItem, subscribeFeature }) => {
  let feature = dataItem;
  const tooltip = React.useRef(null);
  return (
    <div className="k-listview-item product-listview product-row subscription-feature-item">
      <div className="subscription-feature-title">
        <label>{feature?.display_name}</label>
        {feature?.ExpirationDate && (
          <span className="expiry-date">
            Expiration Date: {formatDate(feature?.ExpirationDate)}
          </span>
        )}
        <small className="text-error">
          {!feature?.IsEnabled && !feature?.Issubscribed
            ? "If you want to try out and you are not allowed to do so, send email to sales@smart-is.com"
            : ""}
        </small>
      </div>
      <div
        className="btn-subscribe-wrapper"
        onMouseOver={(event) => {
          return tooltip.current && tooltip.current.handleMouseOver(event);
        }}
        onMouseOut={(event) => {
          return tooltip.current && tooltip.current.handleMouseOut(event);
        }}
      >
        <button
          className={`subscribe-button ${feature?.Issubscribed ? "subscribed" : ""}`}
          onClick={() => subscribeFeature(feature?.feature_id)}
          disabled={!feature?.IsEnabled || feature?.Issubscribed}
          title={
            !feature?.IsEnabled && !feature?.Issubscribed
              ? "If you want to try out and you are not allowed to do so, send email to sales@smart-is.com"
              : ""
          }
        >
          {feature?.Issubscribed ? "Subscribed" : "Subscribe"}
        </button>
      </div>
      <Tooltip
        ref={tooltip}
        anchorElement="target"
        position="left"
        openDelay={300}
      />
    </div>
  );
};
export default ListViewItemRender;
