import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Button } from "@progress/kendo-react-buttons";
import {
  Loader,
  ErrorFallback,
  DropDownInput,
  buildNotification,
  GenerateNotification,
} from "smart-react";
import {
  getOrganizationList,
  requestOrganization,
  getPendingApproval,
} from "../../Services/OrganizationService";
import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";

import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from "../../../../constants/eventDataTypes";
import { Form } from "@progress/kendo-react-form";
import {
  ERROR_MESSAGE,
  CREATE_MESSAGE,
} from "../../../../constants/notificationMessages";

const JoinAnOrganization = () => {
  const [loading, setLoading] = React.useState(false);
  const [organizations, setOrganizations] = React.useState([]);
  const [organization, setOrganization] = React.useState();
  const [pendingRequest, setPendingRequest] = React.useState(false);
  /**
   * handle dropdown Change
   * @e object
   */
  const handleDropdownChange = (e) => {
    setOrganization(e.value);
  };

  /**
   * onSave
   * submit the test data to api
   */
  const onSave = async () => {
    setLoading(true);

    if (!organization || organization?.disabled) {
      GenerateNotification(
        buildNotification({
          title: ERROR_MESSAGE?.title,
          description: "Please Select the organization",
          style: ERROR_MESSAGE?.style,
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
      setLoading(false);
      return;
    }
    let response;

    try {
      response = await requestOrganization({
        data: [organization],
        isServiceWorker: false,
      });
      if (response.Message) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.Message,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      } else {
        GenerateNotification(
          buildNotification(CREATE_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
        pendingApproval();
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * fetch Organizations
   * get the organizations list from api
   */
  const fetchOrganizations = async () => {
    setLoading(true);
    const response = await getOrganizationList({ isServiceWorker: false });
    const updatedOrganizations = response.filter((org) => org.name !== "world");
    setOrganizations(updatedOrganizations);
    setLoading(false);
  };

  /**
   * pendingApproval
   * get the pending Approval organization
   */
  const pendingApproval = async () => {
    setLoading(true);
    const response = await getPendingApproval({
      isServiceWorker: false,
    });
    if (response?.length === 0) {
      fetchOrganizations();
      setPendingRequest(false);
    } else {
      setPendingRequest(true);
      setOrganization(response[0]);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    pendingApproval();
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <React.Suspense fallback={<Loader />}>
        {loading && <Loader />}
        <>
          <div className="main-page-section add-page-section">
            <>
              <Card className="tophead">
                <CardHeader>
                  <div className="action-buttons-container">
                    <p className="k-align-items-center k-d-flex k-mb-0 display-text">
                      Join An Organization
                    </p>
                  </div>
                </CardHeader>
              </Card>
              <Card className="k-mt-3 detail-card">
                <CardHeader>
                  <h6 className="card-title-secondary">Details</h6>
                </CardHeader>
                <CardBody className="detail-card tophead">
                  <div>
                    {pendingRequest ? (
                      <p className="display-text k-align-items-center k-d-flex k-mb-0 k-pl-1">
                        Your request to join {organization?.name} is in
                        progress.
                      </p>
                    ) : (
                      <Form
                        onSubmit={onSave}
                        render={(formRenderProps) => (
                          <div className="card-content">
                            <div className="k-d-flex single-field-row">
                              <div
                                className={`field-wrapper field-wrapper-100`}
                              >
                                <DropDownInput
                                  textField="name"
                                  label="Organization*"
                                  dataItemKey="organization_id"
                                  className="k-align-self-end"
                                  dataSet={organizations}
                                  value={organization}
                                  defaultItem={{
                                    name: "Select Organization ...",
                                    disabled: true,
                                  }}
                                  handleChange={handleDropdownChange}
                                  filterable={true}
                                  fieldWrapperStyles={{
                                    width: "50%",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
              <Card className="k-mt-3">
                <CardHeader>
                  <div className="k-d-flex single-field-row button-form-wrapper">
                    <Button
                      themeColor={"primary"}
                      disabled={pendingRequest}
                      onClick={onSave}
                    >
                      Save
                    </Button>
                  </div>
                </CardHeader>
              </Card>
            </>
          </div>
        </>
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default JoinAnOrganization;
