import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ListView } from "@progress/kendo-react-listview";
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from "../../constants/eventDataTypes";
import { ROLES } from "../../constants/applicationConstants";
import {
  getOrganizationDetail,
  updateOrganization,
  getOrganizationSubscribedProduct,
} from "./Services/OrganizationService";
import { useAuth } from "../Core/Context/AuthContext";
import {
  Loader,
  ErrorFallback,
  TextInput,
  TextAreaInput,
  InputSwitch,
} from "smart-react";
import { Form, Field } from "@progress/kendo-react-form";
import {
  Card,
  CardBody,
  CardHeader,
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import { isValidate, isFromValid } from "../../Utils/Forms/Form";
import {
  hasUserRole,
  getDefaultTenant,
} from "../../Utils/Auth/ValidatePermissions";
import { Tooltip } from "@progress/kendo-react-tooltip";
import JoinAnOrganization from "./Components/JoinAnOrganization/JoinAnOrganization";
import { ListViewItemRender } from "../../Modules/OrganizationProducts/Components/ListView/ListView";
import { buildNotification, GenerateNotification } from "smart-react";
import {
  ERROR_MESSAGE,
  CREATE_MESSAGE,
} from "../../constants/notificationMessages";
import "./Organization.scss";

const Organization = () => {
  const { getTokensFromStorage, user, tenantID } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [formData, setFormData] = React.useState();
  const [expanded, setExpanded] = React.useState("Brazil");
  const [orgId, setOrgId] = React.useState("");
  const [joinOrg, setJoinOrg] = React.useState(false);
  const [validateForm, setValidateForm] = React.useState(false);
  const tooltip = React.useRef(null);
  const [detailsDataModified, setDetailsDataModified] = React.useState(false);
  const [userOrg, setUserOrg] = React.useState([]);
  const requiredFields = ["obj_prefix", "name", "email_domain", "description"];
  React.useEffect(() => {
    const { accessToken } = getTokensFromStorage();
    (async () => {
      setLoading(true);
      const response = await getOrganizationDetail({
        accessToken,
        isServiceWorker: false,
        user,
      });
      setLoading(false);

      let responseData = response?.data?.filter((org) => org.name !== "world");
      if (responseData?.length > 0) {
        let tempData = responseData?.filter(
          (org) => org.tenant_id === tenantID
        );
        if (tempData?.length > 0) {
          setFormData(tempData[0]);
          setOrgId(tempData[0].organization_id);
        } else {
          let tempData = responseData?.filter(
            (org) => org.tenant_id === getDefaultTenant()?.TenantId
          );
          setFormData(tempData[0]);
          setOrgId(tempData[0].organization_id);
        }
        setJoinOrg(false);
      } else {
        setUserOrg(responseData);
        setJoinOrg(true);
      }
    })();
  }, [tenantID]);
  React.useEffect(() => {
    if (orgId) {
      (async () => {
        setLoading(true);
        const response = await getOrganizationSubscribedProduct({
          isServiceWorker: false,
          orgId,
          isSubscribed: true,
        });
        setProducts(response);
        setLoading(false);
      })();
    }
  }, [orgId]);

  React.useEffect(() => {
    setDetailsDataModified(hasUserRole(user, "companyadmin"));
  }, [user]);

  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  /**
   * onSave
   * submit the test data to api
   */
  const onSave = async () => {
    setValidateForm(true);
    if (!isFromValid({ formData, requiredFields })) {
      return;
    }
    setLoading(true);

    let response;

    let data = {
      organization_id: formData?.organization_id,
      organizationVM: formData,
    };

    try {
      setValidateForm(false);
      response = await updateOrganization({
        data,
        isServiceWorker: false,
      });

      if (response.Message) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.Message,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      } else {
        GenerateNotification(
          buildNotification(CREATE_MESSAGE),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <React.Suspense fallback={<Loader />}>
        {loading && <Loader />}
        {joinOrg ? (
          <JoinAnOrganization userOrg={userOrg} />
        ) : (
          <div className="add-test main-page-section add-page-section">
            <Card className="k-mt-3 detail-card">
              <CardHeader>
                <h6 className="card-title-secondary">Details</h6>
              </CardHeader>
              <CardBody className="detail-card-body">
                <div>
                  <Form
                    onSubmit={onSave}
                    render={(formRenderProps) => (
                      <div className="card-content">
                        <div className="k-d-flex single-field-row">
                          <div
                            className={`field-wrapper  ${isValidate(validateForm, formData?.obj_prefix) ? "field-invalid" : "field-valid"}`}
                          >
                            <Field
                              key={"obj_prefix"}
                              id={"obj_prefix"}
                              name={"obj_prefix"}
                              data={formData?.obj_prefix}
                              onChange={handleInputChange}
                              label={"Prefix *"}
                              type={"text"}
                              component={TextInput}
                              optional={false}
                              renderTypes={
                                !detailsDataModified ? "ReadOnly" : ""
                              }
                            />
                            {isValidate(validateForm, formData?.obj_prefix) && (
                              <Error>This field cannot be empty</Error>
                            )}
                          </div>
                          <div
                            className={`field-wrapper ${isValidate(validateForm, formData?.name) ? "field-invalid" : "field-valid"}`}
                          >
                            <Field
                              key={"name"}
                              id={"name"}
                              name={"name"}
                              data={formData?.name}
                              onChange={handleInputChange}
                              label={"Name *"}
                              type={"text"}
                              component={TextInput}
                              optional={false}
                              renderTypes={
                                !detailsDataModified ? "ReadOnly" : ""
                              }
                            />
                            {isValidate(validateForm, formData?.name) && (
                              <Error>This field cannot be empty</Error>
                            )}
                          </div>
                        </div>
                        <div className="k-d-flex single-field-row">
                          <div
                            className={`field-wrapper field-wrapper-100 ${isValidate(validateForm, formData?.description) ? "field-invalid" : "field-valid"}`}
                          >
                            <Field
                              key={"description"}
                              id={"description"}
                              name={"description"}
                              data={formData?.description}
                              onChange={handleInputChange}
                              label={"Description *"}
                              type={"text"}
                              row={2}
                              component={TextAreaInput}
                              style={{
                                width: "100%",
                              }}
                              renderTypes={
                                !detailsDataModified ? "ReadOnly" : ""
                              }
                              optional={false}
                            />
                            {isValidate(
                              validateForm,
                              formData?.description
                            ) && <Error>This field cannot be empty</Error>}
                          </div>
                        </div>
                        <div className="k-d-flex single-field-row">
                          <div className={`field-wrapper`}>
                            <Field
                              key={"email_domain"}
                              id={"email_domain"}
                              name={"email_domain"}
                              data={formData?.email_domain}
                              onChange={handleInputChange}
                              label={"Email Domains"}
                              type={"text"}
                              component={TextInput}
                              optional={false}
                              renderTypes={
                                !detailsDataModified ? "ReadOnly" : ""
                              }
                            />
                          </div>
                          <div className={`field-wrapper k-mt-auto`}>
                            <Field
                              name="active"
                              component={InputSwitch}
                              label="Active"
                              onLabel="Yes"
                              offLabel="No"
                              id="active"
                              checked={formData?.active}
                              onChange={handleInputChange}
                              disabled={!detailsDataModified}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </CardBody>
            </Card>
            {detailsDataModified && (
              <Card className="k-mt-3">
                <CardHeader>
                  <div className="k-d-flex single-field-row button-form-wrapper">
                    <>
                      <Button themeColor={"primary"} onClick={onSave}>
                        Save
                      </Button>
                    </>
                  </div>
                </CardHeader>
              </Card>
            )}
            <Card className="detail-card k-mt-3">
              <CardHeader>
                <h6 className="card-title-secondary">Subscribed Products</h6>
              </CardHeader>
              <CardBody className="!k-px-0 !k-py-0 detail-card">
                <div className="product-row k-p-5 product-page">
                  {products?.length > 0 &&
                    products?.map((product, index) => (
                      <ExpansionPanel
                        title={product.display_name}
                        expanded={expanded === product.product_id}
                        tabIndex={0}
                        key={product.product_id}
                        onAction={(event) => {
                          setExpanded(event.expanded ? "" : product.product_id);
                        }}
                      >
                        <Reveal>
                          {expanded === product.product_id && (
                            <ExpansionPanelContent>
                              <div className="product-col">
                                <Card
                                  className="product-subscription-card "
                                  key={index}
                                >
                                  <CardBody className="product-subscription-body">
                                    <ul className="subscription-features-list">
                                      <ListView
                                        data={product?.Listfeaturevm}
                                        item={(props) => (
                                          <ListViewItemRender
                                            {...props}
                                            isCompanyAdmin={hasUserRole(
                                              user,
                                              ROLES?.OrganizationAdmin
                                            )}
                                          />
                                        )}
                                      />
                                    </ul>
                                  </CardBody>
                                </Card>
                              </div>
                            </ExpansionPanelContent>
                          )}
                        </Reveal>
                      </ExpansionPanel>
                    ))}
                  {products?.length <= 0 && (
                    <h6 className="k-d-block k-mx-auto">No Products Found</h6>
                  )}
                  <Tooltip
                    ref={tooltip}
                    anchorElement="target"
                    position="left"
                    openDelay={300}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        )}
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default Organization;
