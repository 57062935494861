import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  ORGANIZATIONUNAPPROVEDUSERS,
  ROLES,
} from "../../../constants/applicationConstants";
import { DataTableHoc, Loader, ErrorFallback } from "smart-react";
import { GridColumnsList as UnapprovedColumnsList } from "../Components/Filter/UnapprovedFilterElements";
import UnApprovedDataColumns from "../Components/DataColumns/UnApprovedDataColumns";
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from "../../../constants/applicationConstants";
import { getOrganizationDetail } from "../../Organization/Services/OrganizationService";
import {
  hasUserRole,
  getDefaultTenant,
} from "../../../Utils/Auth/ValidatePermissions";
import {
  deleteFilter,
  applyFilter,
  saveFilters,
} from "../../../Utils/Filters/filterSliderHandler";
import { useAuth } from "../../Core/Context/AuthContext";
const BaseOrganizationUnApprovedUserDataTable = React.lazy(
  () => import("../Components/DataTable/UnapprovedUserDataTable")
);
const OrganizationUnApprovedUserDataTable = DataTableHoc(
  BaseOrganizationUnApprovedUserDataTable
);

/**
 * OrganizationUnApprovedUser main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The OrganizationUnApprovedUser component.
 */
const OrganizationUnApprovedUser = () => {
  const [loading, setLoading] = React.useState(false);
  const [userData, setUserData] = React.useState([]);
  const unApproveddataColumnsArray = UnApprovedDataColumns();
  const { getTokensFromStorage, user, tenantID } = useAuth();
  const [orgId, setOrgId] = React.useState("");
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    const { accessToken } = getTokensFromStorage();
    (async () => {
      setLoading(true);
      const response = await getOrganizationDetail({
        accessToken,
        isServiceWorker: false,
        user,
      });
      let responseData = response?.data?.filter((org) => org.name !== "world");
      if (responseData?.length > 0) {
        let tempData = responseData?.filter(
          (org) => org.tenant_id === tenantID
        );
        if (tempData?.length > 0) {
          setOrgId(tempData[0].organization_id);
        } else {
          let tempData = responseData?.filter(
            (org) => org.tenant_id === getDefaultTenant()?.TenantId
          );
          setOrgId(tempData[0].organization_id);
        }
      }
      setLoading(false);
    })();
  }, [tenantID]);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto k-px-4 add-page-section">
        <React.Suspense fallback={<Loader />}>
          {loading && <Loader />}
          <OrganizationUnApprovedUserDataTable
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={ORGANIZATIONUNAPPROVEDUSERS}
            dataColumns={unApproveddataColumnsArray}
            gridColumnsList={UnapprovedColumnsList}
            dataTableName={ORGANIZATIONUNAPPROVEDUSERS}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${ORGANIZATIONUNAPPROVEDUSERS}.DataGrid.RowLayout`}
            pageLengthConfigName={`${ORGANIZATIONUNAPPROVEDUSERS}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            initialSort={{
              field: "id",
              dir: "desc",
            }}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
            orgId={orgId}
            isCompanyAdmin={hasUserRole(user, ROLES?.OrganizationAdmin)}
          />
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default OrganizationUnApprovedUser;
