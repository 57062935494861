import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  ORGANIZATIONUSERS,
  ROLES,
} from "../../../constants/applicationConstants";
import { DataTableHoc, Loader, ErrorFallback } from "smart-react";
import { GridColumnsList } from "../Components/Filter/FilterElements";
import DataColumns from "../Components/DataColumns/DataColumns";
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from "../../../constants/applicationConstants";
import { getOrganizationDetail } from "../../Organization/Services/OrganizationService";
import {
  hasUserRole,
  getDefaultTenant,
} from "../../../Utils/Auth/ValidatePermissions";
import {
  deleteFilter,
  applyFilter,
  saveFilters,
} from "../../../Utils/Filters/filterSliderHandler";
import { useAuth } from "../../Core/Context/AuthContext";
const BaseOrganizationUserDataTable = React.lazy(
  () => import("../Components/DataTable/DataTable")
);
const OrganizationUserDataTable = DataTableHoc(BaseOrganizationUserDataTable);

/**
 * OrganizationApprovedUser main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The OrganizationApprovedUser component.
 */
const OrganizationApprovedUser = () => {
  const [loading, setLoading] = React.useState(false);
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns();
  const { getTokensFromStorage, user, tenantID } = useAuth();
  const [orgId, setOrgId] = React.useState("");
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    const { accessToken } = getTokensFromStorage();
    (async () => {
      setLoading(true);
      const response = await getOrganizationDetail({
        accessToken,
        isServiceWorker: false,
        user,
      });
      let responseData = response?.data?.filter((org) => org.name !== "world");
      if (responseData?.length > 0) {
        let tempData = responseData?.filter(
          (org) => org.tenant_id === tenantID
        );
        if (tempData?.length > 0) {
          setOrgId(tempData[0].organization_id);
        } else {
          let tempData = responseData?.filter(
            (org) => org.tenant_id === getDefaultTenant()?.TenantId
          );
          setOrgId(tempData[0].organization_id);
        }
      }
      setLoading(false);
    })();
  }, [tenantID]);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto k-p-2 add-page-section">
        <React.Suspense fallback={<Loader />}>
          {loading && <Loader />}
          <OrganizationUserDataTable
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={ORGANIZATIONUSERS}
            dataColumns={dataColumnsArray}
            gridColumnsList={GridColumnsList}
            dataTableName={ORGANIZATIONUSERS}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${ORGANIZATIONUSERS}.DataGrid.RowLayout`}
            pageLengthConfigName={`${ORGANIZATIONUSERS}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            initialSort={{
              field: "id",
              dir: "desc",
            }}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
            orgId={orgId}
            isCompanyAdmin={hasUserRole(user, ROLES?.OrganizationAdmin)}
          />
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default OrganizationApprovedUser;
