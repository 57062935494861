import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Loader, ErrorFallback } from "smart-react";
import {
  TabStrip,
  TabStripTab,
  Card,
  CardBody,
} from "@progress/kendo-react-layout";
import OrganizationApprovedUser from "./OrganizationApprovedUser";
import OrganizationUnApprovedUser from "./OrganizationUnApprovedUser";

/**
 * OrganizationUser main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The OrganizationUser component.
 */
const OrganizationUser = () => {
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState(0);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto k-p-2">
        <React.Suspense fallback={<Loader />}>
          {loading && <Loader />}
          <Card className="detail-grid-section k-pt-3">
            <TabStrip
              className="page-tabs"
              selected={selected}
              onSelect={handleSelect}
            >
              <TabStripTab title="Users" contentClassName={`tabs-card`}>
                <Card className={`tabs-card detail-card`}>
                  <CardBody className="!k-px-0">
                    <div className="card-body">
                      <OrganizationApprovedUser />
                    </div>
                  </CardBody>
                </Card>
              </TabStripTab>
              <TabStripTab
                title="Unapproved Users"
                contentClassName={`tabs-card`}
              >
                <Card className={`tabs-card`}>
                  <CardBody className="!k-px-0">
                    <div className="card-body">
                      <OrganizationUnApprovedUser />
                    </div>
                  </CardBody>
                </Card>
              </TabStripTab>
            </TabStrip>
          </Card>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default OrganizationUser;
